<script>
    import { client } from '../lib/data.js';
    import { onMount } from 'svelte';
    import L from 'leaflet';

    var markers = [],
        map,
        contacts = [],
        addresses = [];

    onMount(async () => {
        map = L.map('map');
        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 17,
            minZoom: 5,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }).addTo(map);

        // Fit to NZ
        const centre = L.marker([-40.9799737, 171.9986791]);
        const nzBounds = new L.featureGroup([
            centre,
            // L.marker([-34.2871095, 173.0249851]),
            L.marker([-34.4573964, 172.6179047]),
            L.marker([-34.4634989, 172.6384878]),
            L.marker([-37.6992391, 178.5238398]),
            L.marker([-46.6230855, 168.3583096]),
            L.marker([-45.7589486, 166.4477281]),
            // L.marker([-47.5688604, 168.5503741]),
        ]);
        map.fitBounds(nzBounds.getBounds());
        map.setMaxBounds(nzBounds.getBounds().pad(0.5));

        // Declare Icon
        const redPin = L.icon({
            iconUrl: '/assets/img/map-pin-red.svg',
            iconSize: [24, 24],
            iconAnchor: [12, 24],
        });

        // Fetch Contacts
        contacts = await $client.records.getFullList('people', 200);

        // Add Markers
        markers = [];

        const addressList = contacts.map(f => f.address + ', New Zealand').filter(a => a != '').map(a => a.replace(', ,', ','));
        const addressChunks = addressList.reduce((all,one,i) => {
            const ch = Math.floor(i/20); 
            all[ch] = [].concat((all[ch]||[]),one); 
            return all
        }, []);

        addresses = (await Promise.all(addressChunks.map(async (a) => {
            // await fetch('http://localhost:2084/latlng', {
            return await fetch('https://mapping.izmichael.com/latlng', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    addresses: a
                })
            }).then(res => { return res.json(); });
        }))).map((b) => (b).items).flat();

        contacts.filter(f => f.address != '').forEach(async (family) => {
            const addressCoords = addresses.find(a => a.query == (family.address + ', New Zealand'))?.result ?? { lat: 0, lon: 0 };
            let marker = L.marker([addressCoords.lat, addressCoords.lon], {
                icon: redPin,
            })
                .bindTooltip(
                    (family.people.length > 1
                        ? unique(family.people.map((person) => person.last))
                              .sort()
                              .join('-') + ' Family'
                        : family.people[0].first + ' ' + family.people[0].last)
                    + '<br>' + family.address
                )
                .addTo(map);
            markers = [...markers, marker];
        });
    });

    function unique(array) {
        return [...new Set(array)];
    }

    let filterQuery = '';
</script>

<section class="justify-evenly flex flex-row items-center flex-1 w-full max-h-full gap-4 p-5">
    <div class="mapWrapper w-2/3 h-full overflow-hidden border-2 rounded-lg">
        <div id="map" class="w-full h-full" />
    </div>

    <div class="flex flex-col items-center justify-start flex-1 h-full max-h-full overflow-x-hidden overflow-y-scroll">
        {#if addresses}
        <input placeholder="Filter..." bind:value={filterQuery} class="bg-slate-300 sticky top-0 w-full px-2 py-1 mb-2 text-black rounded-lg outline-none" >
        {#each contacts
            .filter(f => f.address != '')
            .filter(f => f.address.toLowerCase().includes(filterQuery.toLowerCase()) || f.people.map(p => (p.first ?? '' + ' ' + p.last ?? '').toLowerCase()).join(' ').includes(filterQuery.toLowerCase()))
            .sort((a, b) => a.people[0].last > b.people[0].last ? 1 : -1)
        as family}
            <button class="hover:bg-gray-100 w-full p-2 rounded-lg" on:click={() => {
                map.flyTo([addresses.find(a => a.query == family.address)?.result.lat ?? 0, addresses.find(a => a.query == family.address)?.result.lon ?? 0])
            }}>
                <p class="w-full font-bold text-left">{(family.people.length > 1
                    ? unique(family.people.map((person) => person.last))
                        .sort()
                        .join('-') + ' Family'
                    : family.people[0].first + ' ' + family.people[0].last)}</p>
                <p class="w-full text-left">{family.address}</p>
            </button>
        {/each}
        {:else}
        <p>Loading...</p>
        {/if}
    </div>
</section>

<style>
</style>
