<script>
    import { Confirm } from 'svelte-confirm';
    import Select from 'svelte-select';
    export let people = [], tags = [];

    async function deleteSel(i) {
        people.splice(i, 1);
        people = people;
    };
</script>

<div class="flex flex-col items-center justify-start flex-1 h-full max-h-full gap-5 overflow-y-auto">
    <h1 class="-mb-3 text-2xl font-bold">Family Members</h1>
    <button on:click={()=> {people.push({'tags':'','perms':{'sms':false}}); people = people;}} class="sticky top-0 w-full p-2 bg-green-200">Add Person</button>

    {#each people as person, i}
        <div class="grid w-full grid-cols-2 gap-2">
            <input bind:value={people[i].first} placeholder="First Name">
            <input bind:value={people[i].last} placeholder="Last Name">
            <input bind:value={people[i].birth} type="date">
            <input bind:value={people[i].mobile} placeholder="Mobile Number">
            <input bind:value={people[i].email} type="email" placeholder="email@address.tld">
            <label for="editor-sms" class="flex flex-row items-center justify-center w-full p-2 bg-gray-100 border-b-2 border-black select-none">
                <p class="mr-3">Bulk SMS</p>
                <input id="editor-sms" bind:checked={people[i].perms.sms} type="checkbox">
            </label>
            <Select containerClasses="input" items={tags} bind:value={people[i].tags} isMulti={true} isCreatable={true} on:select={(e) => { Array.isArray(e.detail) ? console.log(people[i].tags) : people[i].tags = [] }}></Select>
            <!-- <input bind:value={people[i].tags} name="Tags" placeholder="Tags"> -->


            <Confirm confirmTitle="Delete" cancelTitle="Cancel" let:confirm={confirmThis}>
                <button class="w-full p-2 bg-red-200" on:click={() => confirmThis(deleteSel, i)}>Delete {people[i].first}</button>
                
                <span slot="title">
                    Are you sure you want to delete this person?
                </span>
                <span slot="description">
                    You cannot reverse this action.
                </span>
            </Confirm>
        </div>
    {/each}
</div>

<style>
    input:not(label>input), .input {
        @apply p-2 bg-gray-100 border-b-2 border-black w-full;
    }
</style>