<div class="shadow-elevate-lg flex flex-row items-center justify-between w-full p-2 rounded-lg">
    <div class="h-7 flex flex-row items-center justify-start pr-4 mr-4 border-r-2 border-gray-500">
        <img src="/favicon.png" alt="OCC Logo" class="h-7 w-7 mr-2">
        <h1 class="text-xl">OCC Office Portal</h1>
    </div>
    <div class="h-7 flex flex-row items-center justify-start gap-12 px-4 ml-4 border-l-2 border-gray-500">
        <a href="/contacts" class="text-xl">Contacts</a>
        <a href="/sms" class="text-xl">Texting</a>
        <a href="/map" class="text-xl">Map</a>
    </div>
</div>