<script>
    import { client } from '../lib/data.js';
    import { toast } from '@zerodevx/svelte-toast';
    import { Confirm } from 'svelte-confirm';
    import Papa from 'papaparse';
    import fileDownload from 'js-file-download';
    import dayjs from 'dayjs';

    import {
        onMount
    } from 'svelte';
    import PeopleEditor from '../lib/PeopleEditor.svelte';

    let people = [],
        selected = {
            'id': '',
            'people': [],
            'address': '',
            'phone': '',
            'notes': '',
            'perms': {
                'online_dir': false
            }
        },
        editing = false,
        // searching = false,
        viewmode = 'family',
        editmode = 'create',
        dburl = `https://hmtiujkhrxaeowhqczpg.supabase.co/rest/v1/PeopleTEST?apikey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2VydmljZV9yb2xlIiwiaWF0IjoxNjMxMDYyNTY0LCJleHAiOjE5NDY2Mzg1NjR9.9PgZl1oRIBrkOt5As_rik87Aoac4I8GmWdx-tKX7Ric`;

    onMount(() => {
        getData();
    });

    async function getData() {
        people = await $client.records.getFullList('people', 200);
        people = people.sort((a, b) => a.people[0]?.last > b.people[0]?.last ? 1 : -1);
    }

    function blank() {
        selected = {
            'id': '',
            'people': [],
            'address': '',
            'phone': '',
            'notes': '',
            'perms': {
                'online_dir': false
            }
        };
    };

    function toggleEditor() {
        editing == true ? editing = false : editing = true;
    };

    async function copyEmails() {
        copyTextToClipboard(people.map(a => a.people).flat().filter(b => b.email).map(c => c.email).join('; ') + ';');
    };
    async function createNew() {
        editmode = 'create';
        editing = true;
        blank();
    };
    async function editSelected() {
        editmode = 'edit';
        editing = true;
    };
    function cancelEditing() {
        editing = false;
        blank();
    };
    async function deleteSelected() {
        await $client.records.delete('people', selected.id);
        editing = false;
        blank();
        getData();
    };

    // Lib

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    };

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            toast.push(`Successfully copied to clipboard.`);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            toast.push(`Failed to copy to clipboard.`);
        }

        document.body.removeChild(textArea);
    };

    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            console.log('Async: Copying to clipboard was successful!');
            toast.push(`Successfully copied to clipboard.`);
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
            toast.push(`Failed to copy to clipboard.`);
        });
    };

    function age(dateString) {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    // Searching
    function search(inp, col) {
        [...document.querySelectorAll(`thead:nth-child(1) input`)].filter((a, i) => i != col).forEach(b => {
            b.value = '';
        });

        var input = inp.toUpperCase(),
            list = document.querySelector('tbody').querySelectorAll('tr');

        for (var i = 0; i < list.length; i++) {
            let cell = list[i].querySelectorAll('td')[col];
            if (cell) {
                let content = cell.textContent || cell.innerText;
                if (content.toUpperCase().indexOf(input) > -1) {
                    list[i].style.display = '';
                } else {
                    list[i].style.display = 'none';
                }
            }
        }
    };
</script>

<div class="relative flex flex-col flex-1 h-full max-h-full p-2 overflow-hidden">
    <div id="btn-bar" class="flex flex-row w-full mb-2 overflow-hidden rounded-lg h-11">
        <img class="p-2 bg-pink-400 w-11" on:click={()=> viewmode == 'family' ? viewmode = 'person' : viewmode = 'family'} src="/assets/img/{viewmode}.svg" alt="{viewmode} Icon">
        
        <!-- <img class="bg-purple-400 {editing == false ? 'p-2 w-11' : 'p-0 w-0'}" on:click={()=> searching == true ? searching = false : searching = true} src="/assets/img/search.svg" alt="Search Icon">
        <input class="bg-purple-300 placeholder:text-black {editing == false && searching == true ? 'p-2 flex-1' : 'p-0 w-0'}" id="searchinput" placeholder="Search for a person, tag, or mobile number..." on:keyup={() => search()}> -->
        
        <p class="bg-yellow-400 {editing == false ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> copyEmails()}>Copy All Emails</p>
        <p class="bg-purple-400 {editing == false ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> fileDownload(Papa.unparse(people.map(a => a.people).flat().map(b => { delete b.perms; b.tags = b.tags.map(c => c.label).join(', '); return b })), 'OCC-Portal-Export-' + dayjs().format('YYYY-MM-DD_HH-mm-ss') + '.csv', 'text/csv')}>Download as CSV</p>
        
        <p class="bg-green-400 {editing == false ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> createNew()}>Create New Family</p>
        <p class="bg-blue-400 {editing == false && selected.id != '' ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> editSelected()}>Edit Selected Family</p>

        <Confirm confirmTitle="Delete" cancelTitle="Cancel" let:confirm={confirmThis}>
            <p class="bg-red-400 {editing == false && selected.id != '' ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> confirmThis(deleteSelected)}>Delete Selected Family</p>
            
            <span slot="title">
                Are you sure you want to delete this family?
            </span>
            <span slot="description">
                You cannot reverse this action.
            </span>
        </Confirm>
        
        <p class="bg-gray-400 {editing == true ? 'p-2 flex-1' : 'p-0 w-0'}" on:click={()=> cancelEditing()}>Cancel Editing</p>
    </div>

    <div class="flex flex-row flex-1 h-full max-h-full overflow-hidden rounded-lg">
        <div class="flex-1 h-full max-h-full overflow-y-auto">
            <table>
                <thead class="sticky top-0 font-bold text-white bg-slate-400">
                    {#if viewmode == 'family'}
                        <tr>
                            <td>People</td>
                            <td>Address</td>
                            <td>Home Phone</td>
                            <td>E-Directory</td>
                        </tr>
                        <tr class="gap-1">
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 0); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 1); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 2); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 3); }}>
                        </tr>
                    {:else}
                        <tr>
                            <td>Full Name</td>
                            <td>Birthday</td>
                            <td>Tags</td>
                            <td>Mobile Number</td>
                            <td>Email</td>
                            <td>Bulk Texts</td>
                        </tr>
                        <tr class="gap-1">
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 0); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 1); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 2); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 3); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 4); }}>
                            <input placeholder="Filter..." on:keyup={e => { search(e.target.value, 5); }}>
                        </tr>
                    {/if}
                </thead>
                <tbody>
                    {#if viewmode == 'family'}
                        {#each people as family, i}
                            <tr on:click={() => selected == family ? blank() : selected = family} class="{selected.id == family.id ? 'bg-gray-200' : ''}">
                                <td class="flex flex-col">{#each family.people as person, i}
                                    <span>{person.first} {person.last}</span>
                                {/each}</td>
                                <td>{family.address}</td>
                                <td>{family.phone}</td>
                                <td>{family.perms.online_dir == true ? 'Yes' : 'No'}</td>
                            </tr>
                        {/each}
                    {:else}
                        {#each people as family, i}
                            {#each family.people as person, i}
                                <tr on:click={() => selected == family ? blank() : selected = family} class="{selected.id == family.id ? 'bg-gray-200' : ''}">
                                    <td>{person.first || ''} {person.last || ''}</td>
                                    <td>{(person.birth != undefined && person.birth != '' && person.birth != ' ') ? dayjs(person.birth).format('MMMM D, YYYY') + ' - ' + age(person.birth) + ' Years' : ''}</td>
                                    <td>{[...person.tags].map(a => a.label).join(', ')}</td>
                                    <td>{person.mobile || ''}</td>
                                    <td>{person.email || ''}</td>
                                    <td>{person.perms.sms == true ? 'Yes' : 'No'}</td>
                                </tr>
                            {/each}
                        {/each}
                    {/if}
                </tbody>
            </table>
        </div>
    
        <div class="flex flex-row {editing == true ? 'w-2/3 border-l-4 p-2 ml-2 border-slate-600' : 'w-0 ml-0 border-l-0 p-0'} transition-all duration-500 ease-in-out h-full max-h-full">
            <PeopleEditor bind:people={selected.people} tags={people.map(a => a.people).flat().filter(b => b.tags).flat().map(c => c.tags).flat().filter((d, e, f) => f.findIndex(g => (JSON.stringify(g) === JSON.stringify(d))) === e)}></PeopleEditor>
            <div class="flex flex-col items-center justify-start flex-1 max-h-full gap-1 ml-2 overflow-y-scroll editor">
                <h1 class="mb-2 text-2xl font-bold">Family Details</h1>
    
                <p class="w-full text-left">Address</p>
                <textarea bind:value={selected.address} class="w-full p-2 mb-4 bg-gray-100 border-b-2 border-black shadow-md" placeholder="Address" title="Address" rows="3"></textarea>
                
                <p class="w-full text-left">Home Phone</p>
                <input bind:value={selected.phone} class="w-full p-2 mb-4 bg-gray-100 border-b-2 border-black shadow-md" placeholder="Home Phone" title="Home Phone">
    
                <p class="w-full text-left">Additional Notes</p>
                <textarea bind:value={selected.notes} class="w-full p-2 mb-4 bg-gray-100 border-b-2 border-black shadow-md" placeholder="Additional Notes" title="Additional Notes" rows="5"></textarea>
    
                <label for="editor-dir" class="flex flex-row items-center justify-center w-full p-2 mb-4 bg-gray-100 border-b-2 border-black shadow-md select-none">
                    <p class="mr-3">Show on E-Directory</p>
                    <input id="editor-dir" bind:checked={selected.perms.online_dir} type="checkbox">
                </label>
                
                <button class="w-full col-span-2 p-2 bg-blue-200" on:click={async () => {
                    if (editmode == 'create') {
                        await $client.records.create('people', selected);
                    } else {
                        await $client.records.update('people', selected.id, selected)
                    };
                    getData();
                    toggleEditor();
                    blank();
                }}>Save</button>
            </div>
        </div>
    </div>
</div>

<style>
    #btn-bar p, #btn-bar img {
        @apply h-full flex flex-col justify-center items-center cursor-pointer select-none text-white font-semibold overflow-hidden transition-all duration-500 ease-in-out;
    }

    #btn-bar input {
        @apply h-full flex flex-col justify-center items-center cursor-text select-none text-black overflow-hidden transition-all duration-500 ease-in-out;
    }

    table {
        @apply w-full h-full justify-start flex flex-col items-center overflow-auto max-h-full;
    }

    thead, tbody {
        @apply w-full;
    }

    thead tr {
        @apply border-none;
    }

    thead:nth-child(1) {
        @apply gap-1 px-1;
    }

    thead:nth-child(1) input {
        @apply bg-slate-300 py-1 px-2 flex-1 rounded-lg text-black;
    }
    thead:nth-child(1) input::placeholder {
        @apply text-black;
    }

    tr {
        @apply w-full py-1 border-b flex flex-row justify-evenly items-center;
    }

    tbody tr:hover {
        @apply bg-slate-100;
    }

    td {
        @apply p-1 px-2 text-center w-[25%];
    }

    input, textarea {
        @apply shrink-0 resize-none;
    }
</style>