// https://github.com/jorgegorka/svelte-router/blob/master/README.md

import Home from "./routes/Home.svelte";
import Login from "./routes/Login.svelte";
import Contacts from "./routes/Contacts.svelte";
import SMS from "./routes/SMS.svelte";
import Map from "./routes/Map.svelte";
import NotFound from "./routes/NotFound.svelte";

function loggedIn() {
    if (
        window.localStorage.getItem("pocketbase_auth") &&
        JSON.parse(window.localStorage.getItem("pocketbase_auth")) != {}
    )
        return true;
}

const routes = [
    {
        name: "/",
        component: Home,
        onlyIf: { guard: loggedIn, redirect: "/login" },
    },
    {
        name: "/login",
        component: Login,
        onlyIf: { guard: () => loggedIn() != true, redirect: "/" },
    },
    {
        name: "/contacts",
        component: Contacts,
        onlyIf: { guard: loggedIn, redirect: "/login" },
    },
    {
        name: "/sms",
        component: SMS,
        onlyIf: { guard: loggedIn, redirect: "/login" },
    },
    {
        name: "/map",
        component: Map,
        onlyIf: { guard: loggedIn, redirect: "/login" },
    },
    {
        name: "404",
        component: NotFound,
    },
];

export { routes };
