<script>
    import { client } from '../lib/data.js';
    import { toast } from '@zerodevx/svelte-toast';
    import { Confirm } from 'svelte-confirm';
    import clip from "text-clipper";
    import Select from 'svelte-select';

    let message = '';
    let sends = [];
    let sendStage = 'inactive';

    async function send() {
        sendStage = 'start';
        await $client.records.create('smsReceived', {
            'from': '+64212647434',
            'to': people.filter(a => a.perms.sms == true).map(b => b.mobile.replace('0', '+64')).join('; '),
            'message': decodeURIComponent(encodeURIComponent('OCC: ' + message))
        });
        
        let perChunk = 10;
        let chunks = people.filter(b => b.perms.sms == true).sort((c, d) => c.last > d.last ? 1 : -1).map(e => e.mobile).reduce((all,one,i) => {
            const ch = Math.floor(i/perChunk); 
            all[ch] = [].concat((all[ch]||[]),one); 
            return all
        }, []);
        for (const a of chunks) {
            for (const b of a) {
                let run = await fetch(`https://joinjoaomgcd.appspot.com/_ah/api/messaging/v1/sendPush?apikey=89e43f0d66ef4a309ae9ebfe3a9843ae&deviceId=6369d5360b9649f49e44d1c3c51b0f5b&deviceNames=Galaxy%20J7%20Pro29&smsnumber=${b}&smstext=${encodeURIComponent('OCC: ' + message)}`)
                    .then(res => {return res.json()})
                sends = [...sends, run];
                await sleep(1);
            };
            await sleep(30);
        };
        message = '';
        toast.push(`Sent ${sends.filter(a => a.success == true && a.userAuthError == false).length} messages.<br>Failed to send ${sends.filter(a => a.success != true || a.userAuthError != false).length} messages.`)
        sendStage = 'inactive';
    };

    // Person Selector

    import { onMount } from 'svelte';

    let people = [], allpeople = [], received = [], receivedF = true, dbkey = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoic2VydmljZV9yb2xlIiwiaWF0IjoxNjMxMDYyNTY0LCJleHAiOjE5NDY2Mzg1NjR9.9PgZl1oRIBrkOt5As_rik87Aoac4I8GmWdx-tKX7Ric`;
    onMount(async () => {
        people = await $client.records.getFullList('people', 400);
        received = await $client.records.getFullList('smsReceived', 400, {
            sort: '-created'
        });

        allpeople = people.map(a => a.people).flat().filter(b => b.mobile);
        people = people.map(a => a.people).flat().filter(b => b.mobile).filter(b => b.perms.sms == true);
        people = people.sort((a, b) => a.last > b.last ? 1 : -1);

        received.sort((a, b) => a.received_at < b.received_at ? 1 : -1);

        setInterval(async () => {
            received = await $client.records.getFullList('smsReceived', 400, {
                sort: '-created'
            });

            received.sort((a, b) => a.received_at < b.received_at ? 1 : -1);
        }, 60000); // 1 Minute
    });

    function search() {
        var input = document.getElementById('searchinput').value.toUpperCase(),
            list = document.getElementById('peoplelist').querySelectorAll('label');

        for (var i = 0; i < list.length; i++) {
            let cell1 = list[i].querySelectorAll('p')[0];
            let cell2 = list[i].querySelectorAll('p')[1];
            let cell3 = list[i].querySelectorAll('p')[2];
            if (cell1 || cell2 || cell3) {
                let content1 = cell1.textContent || cell1.innerText;
                let content2 = cell2.textContent || cell2.innerText;
                let content3 = cell3.textContent || cell3.innerText;
                if (content1.toUpperCase().indexOf(input) > -1 || content2.toUpperCase().indexOf(input) > -1 || content3.toUpperCase().indexOf(input) > -1) {
                    list[i].style.display = '';
                } else {
                    list[i].style.display = 'none';
                }
            }
        }
    };

    function sleep(s) {
        return new Promise(resolve => setTimeout(resolve, (s * 1000)));
    };

    $: console.log('sendStage: ' + sendStage)
</script>

{#if sendStage == 'start'}
    <div class="absolute top-0 left-0 flex flex-col items-center justify-center w-screen h-screen overflow-hidden bg-white z-[1000]">
        <h1 class="text-6xl font-bold">Sending Messages...</h1>
        <h2 class="my-3 font-mono text-5xl">DO NOT TOUCH THIS DEVICE UNTIL COMPLETION.</h2>
        <div class="w-1/2 h-8 mt-3 overflow-hidden bg-gray-400 rounded-full">
            <span class="block h-full py-1 transition-all duration-500 ease-in-out bg-blue-600" style="width: {(sends.length / people.filter(b => b.perms.sms == true).length) * 100}%;"></span>
        </div>
    </div>
{/if}

<div class="flex flex-row items-start justify-center flex-1 w-full max-h-full gap-2 p-4 overflow-hidden">
    <div id="peoplelist" class="flex flex-col items-center justify-start flex-1 max-h-full gap-1 p-1 overflow-hidden">
        <h1 class="w-full mb-2 text-4xl font-bold text-center">Send Text To:</h1>
        <input id="searchinput" on:keyup={() => search()} placeholder="Search for a person or mobile..." class="sticky top-0 w-full p-1 border-b-2 border-gray-500 rounded-none outline-none">
        <div class="flex flex-row items-center justify-between w-full">
            <button class="hover:bg-blue-200 w-full p-2 bg-blue-300" on:click={() => { people.map(a => a.perms.sms = true); people = people; }}>Select All</button>
            <button class="hover:bg-blue-200 w-full p-2 bg-blue-300" on:click={() => { people.map(a => a.perms.sms = false); people = people; }}>Select None</button>
        </div>
        <div class="grid w-full max-h-full grid-cols-1 gap-2 p-1 overflow-y-scroll">
            {#each people as person, i}
                <label for={person.first + '-' + person.last} class="shadow-elevate-md flex flex-col items-center justify-center w-full h-full px-2 py-1 text-center rounded-lg">
                    <div class="flex flex-row items-center justify-center w-full">
                        <input id={person.first + '-' + person.last} type="checkbox" bind:checked={people[i].perms.sms} class="mr-2">
                        <p class="flex-1 text-left">{person.first} {person.last}</p>
                        <p class="flex-1 text-right">{person.mobile}</p>
                    </div>
                    <p class="w-full text-left">{(person.tags || []).map(a => a.label).join(', ')}</p>
                </label>
            {/each}
        </div>
    </div>

    <div class="flex flex-col items-center justify-start w-1/3 h-full">
        <h1 class="w-full mb-2 text-4xl font-bold text-center">Bulk Texting Tool</h1>

        <div class="flex flex-col items-center justify-center w-full">
            <p class="w-full mb-1 text-left">Phone Numbers to send to: {people.filter(a => a.perms.sms == true).length}</p>
            <textarea class="input select-none" rows="5">{people.filter(b => b.perms.sms == true).map(c => c.first + ' ' + c.last).join(', ')}</textarea>

            <p class="w-full mt-6 mb-1 text-left">Message:</p>
            <textarea bind:value={message} maxlength="155" rows="5" class="resize-none"></textarea>
            <p class="w-full text-right">{155 - message.length} Characters Left</p>
        </div>

        <Confirm confirmTitle="Send" cancelTitle="Cancel" let:confirm={confirmThis}>
            <button on:click={() => confirmThis(send)} class="button h-12">Send</button>
            
            <span slot="title">
                Are you sure you want to send this message to {people.filter(a => a.perms.sms == true).length} people?
            </span>
            <span slot="description">
                You will send the following message:<br>{message}
            </span>
        </Confirm>

        <p>Sent to {sends.length} people, {people.filter(b => b.perms.sms == true).length - sends.length} remaining.</p>
    </div>

    <div class="flex flex-col items-center justify-around w-1/3 h-full">
        <h1 class="w-full mb-2 text-4xl font-bold text-center">Received Texts</h1>
        <Select placeholder="Showing All Messages - Click to filter by person" items={allpeople.filter(a => a.mobile).map(b => { b.label = b.first + ' ' + b.last + ' (' + b.mobile + ')'; return b }).sort((c, d) => c.last > d.last ? 1 : -1)} containerClasses="w-full mb-1" on:select={e => {
            let selected = e.detail;
            receivedF = received.filter(a => a.to.split('; ').includes(selected.mobile.replace('0', '+64')) || a.from == selected.mobile.replace('0', '+64'));
            if (e.detail == null) {
                receivedF = true;
            };
        }} on:clear={() => receivedF = true}></Select>
        <div class="flex flex-col justify-start flex-1 w-full max-h-full gap-2 p-1 overflow-y-auto">
            {#each received.filter(a => allpeople.map(b => b.mobile).includes(a.from.replace('+64', '0')) || a.from == '+64212647434') as txt, i}
                {#if receivedF == true || receivedF.includes(txt)}
                    <div class="w-[90%] p-2 rounded-md shadow-elevate-md {txt.from == '+64212647434' ? 'text-right self-end bg-brand text-white' : 'text-left self-start text-black bg-cyan-100'}">
                        {#if txt.from == '+64212647434'}
                            <p class="italics w-full overflow-hidden text-sm">To {clip(txt.to.split('; ').map(a => {
                                let person = allpeople.filter(b => b.mobile == a.replace('+64', '0'));
                                if (person.length < 1) return 'Unknown';
                                return person[0].first + ' ' + person[0].last;
                            }).join(', '), 55)} ({txt.to.split('; ').length} {txt.to.split('; ').length > 1 ? 'people' : 'person'})</p>
                        {:else}
                            <p class="w-full text-sm italic">From {allpeople.filter(a => a.mobile == txt.from.replace('+64', '0'))[0].first} {allpeople.filter(b => b.mobile == txt.from.replace('+64', '0'))[0].last}</p>
                        {/if}
                        <p class="w-full my-1">{txt.message}</p>
                        <p class="w-full text-sm italic">{new Date(txt.received_at).toDateString()} - {new Date(txt.received_at).toLocaleTimeString()}</p>
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</div>

<style>
    textarea, .button, .input {
        @apply p-2 rounded-none w-full border-2 border-gray-500 outline-none resize-none;
    }

    textarea:focus, .button:focus, .input:focus {
        @apply border-gray-700;
    }
</style>