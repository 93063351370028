<svelte:head>
    <title>OCC Office Portal</title>
</svelte:head>

<script>
    import { client, user } from './lib/data.js';
    import { Router } from 'svelte-router-spa'
    import { routes } from './routes'
    import { SvelteToast } from '@zerodevx/svelte-toast';

    import Header from './lib/Header.svelte';
    import Footer from './lib/Footer.svelte';
    import PocketBase from 'pocketbase';
    import { onMount } from 'svelte';
    $client = new PocketBase('https://portaldb.occ.net.nz');

    let ready = false;
    onMount(async () => {
        if ($user.token) {
            try {
                $user = await $client.users.refresh();
            } catch {
                $client.AuthStore.clear();
                window.location = window.location;
                return;
            };

            if ($user.model) {
                $user.user = $user.model;
                // delete $user.model;
            };
            $user.user.profile = await $client.records.getOne('profiles', $user.user.profile.id);
            
            ready = true;
        } else {
            ready = true;
        };
    });
</script>

<div class="flex flex-col items-center justify-between w-screen h-screen max-h-screen p-4 overflow-hidden">
    <SvelteToast/>
    <Header></Header>
    
    <div class="flex flex-col flex-1 w-full max-h-full my-4 overflow-hidden rounded-lg shadow-elevate-lg">
        {#if ready == true}
        <Router {routes}/>
        {/if}
    </div>
    
    <Footer></Footer>
</div>

<style global>
    @tailwind base;
    @tailwind components;
    @tailwind utilities;

    * {
        outline: none;
    }
    
    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
</style>