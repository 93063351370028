<script>
    import { client } from './data.js';
</script>

<div class="flex flex-row items-center justify-between w-full p-2 rounded-lg shadow-elevate-lg">
    <a href="https://izmichael.com/" class="flex flex-row items-center justify-start pr-4 mr-4 border-r-2 border-gray-500 h-7">
        <img src="https://izmichael.com/favicon.png" alt="Michael Martin" class="mr-2 rounded-full h-7 w-7">
        <h1 class="flex-1 text-left">Built by Michael Martin</h1>
    </a>
    <button class="flex flex-row items-center justify-start px-4 ml-4 text-right border-l-2 border-gray-500 cursor-pointer select-none h-7" on:click={() => {
        $client.AuthStore.clear();
        window.location = window.location;
    }}>Logout and Quit</button>
</div>