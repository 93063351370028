<script>
	import { client, user } from '../lib/data';
    let btn, email = '', password = '', err = { show: false, message: '' };
    
    async function logIn() {
        btn.disabled = true;

        if (email.length < 1 || password.length < 1) {
            err = { show: true, message: 'Invalid Email or Password' };
            return btn.disabled = false;
        } else if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) != true) {
            err = { show: true, message: 'Invalid Email' };
            return btn.disabled = false;
        } else {
            err.show = false;
        };
        
        try {
            $user = await $client.users.authViaEmail(email, password);

            if (!$user.token) throw $user;

            $user = await $client.users.refresh();
            if ($user.model) {
                $user.user = $user.model;
                delete $user.model;
            };

            email = '', password = '';
            window.location = '/';
        } catch (error) {
            err = { show: true, message: error.message };
            return btn.disabled = false;
        };
    };
</script>

<div class="flex flex-col items-center justify-center w-full h-full">
    <div class="flex flex-col items-center justify-center w-1/2">
        <h1 class="mb-12 text-6xl font-bold text-center">Welcome to the<br>OCC Office Portal</h1>
        <p>Email Address</p>
        <input placeholder="john.doe@email.tld" bind:value={email} name="email">
        <p>Password</p>
        <input placeholder="*********" type="password" bind:value={password} name="password">
        <button id="submit" class="w-full px-5 py-2 text-white bg-brand active:bg-brand hover:bg-brand-light rounded-xl" on:click={() => logIn()} bind:this={btn}>Log In</button>
        {#if err.show == true}
        <p class="pt-1 font-bold text-brand">{err.message}</p>
        {/if}
    </div>
</div>

<style>
    p {
        @apply w-full text-left mb-1;
    }

    a {
        @apply w-full mb-1 text-brand underline hover:no-underline;
    }

    input {
        @apply p-3 rounded-xl overflow-hidden bg-slate-200 transition-shadow duration-200 ease-in-out w-full mb-3 placeholder:text-gray-600;
    }
    
    input:focus, input:active {
        @apply ring-2 ring-brand;
    }
</style>